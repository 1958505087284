.notifications-badge{
    position: absolute !important;
    top: 13px !important;
    right: -12px !important;
    padding: 1px -2px;
    border-radius: 2px;
}
.notifications-dropdown{
    width: 350px;
    max-height: 500px;
    overflow-y: auto;
    .action-log-item{
        padding-right: 0.75rem !important;
    }
}

.action-log-item{
    border-bottom: 1px solid $gray-200;
    &.unread{
        background-color: $bluish-highlight;
    }
    &:hover{
        background-color: $gray-100;
    }
    .icon-container{
        margin-top: 0.7rem;
    }
}