.table-dropdown-btn{
    padding: 0.3rem 0.5rem;
    color: $gray-500;
    border-radius: 100%;
    &:hover{
        color: $gray-700;
        background-color: $gray-200;
    }
}

.dropdown-menu{
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    // width: 250px;
    .dropdown-item{
        padding-left: 3rem;
        position: relative;
        cursor: pointer;
        svg{
            position: absolute;
            top: 8px;
            left: 15px;
            color: $text-muted;
        }
        &:hover{
            background-color: $gray-200;
        }
    }
}