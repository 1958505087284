table, table.table{
    font-family: "Cabin", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    th{
        font-weight: bold;
        vertical-align: middle;
    }
    td{
        vertical-align: middle;
    }
}

.clickable-row-table{
    tbody > tr:hover{
        background-color: $gray-100;
        cursor: pointer;
    }
}