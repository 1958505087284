// Assign to a span to make it look like a link
.like-link{
    color: $link-color;
    text-decoration: $link-decoration;
    cursor: pointer;
    &:hover{
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.no-link-decoration{
    text-decoration: none !important;
    color: inherit !important;
}

button:disabled{
    cursor: not-allowed;
}

.container-footer{
    border-top: 1px solid $primary;
    text-align: center;
    padding-top: 1rem;
}

.breadcrumb{
    font-family: "Cabin", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.datetime{
    font-size: 0.85rem;
    i{
        color: $gray-500;
    }
}

label{
    font-weight: 200;
    font-size: 0.9rem;
    margin-bottom: 0;
    text-transform: uppercase;
    cursor: pointer;
}

.not-allowed{
    color: $text-muted !important;
    cursor: not-allowed !important;
}

// HACKS HERE
.ck-balloon-panel{
    z-index: 10000 !important;
}