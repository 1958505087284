.ReactModal__Body--open,
.ReactModal__Html--open {
    overflow: hidden;
}

.modal{
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 98%;
}
.modal-dialog{
    max-width: 98%;
}
.modal-content{
    max-width: 98%;
}