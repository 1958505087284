.drag-drop-table{
    border: 1px solid $border-color;
    background-color: $gray-200;
    width: 100%;
    th{
        padding: 0.2rem 1rem;
    }
    th:last-child{
        text-align: center;
    }
}

.drag-drop-list-item{
    background-color: $white;
    border: 1px solid $border-color;
    &.dragged{
            display: table;
        td{
            display: table-cell;
            vertical-align: middle;
        }
    }
    td{
        padding: 0.5rem 1rem;
        &.drag-handle{
            text-align: center;
            color: $gray-500;
            &:hover{
                color: $gray-900;
                cursor: row-resize;
            }
        }
    }
}
