.data-grid-container{
    max-height: 600px;
    overflow-y: auto;
    display: block;
    .data-grid{
        margin: 0 auto;
        .cell{
            padding: 0.2em 0.5rem !important;
        }
        .cell.read-only{
            text-align: left !important;
            background-color: $white !important;
            color: $gray-900 !important;
        }
        .cell.unique{
            font-weight: bold !important;
            background-color: $gray-200 !important;
        }
        tr:first-child .cell{
            background-color: $gray-400 !important;
        }
    }
}
