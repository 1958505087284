.help-content{
    h5{
        font-size: 1.15rem;
        margin-top: 1rem;
        padding-bottom: 5px;
        border-bottom: 1px solid $primary;
    }
    img{
        max-width: 100%;
        height: auto;
        margin-bottom: 0.7rem;
        border: 1px solid #999;
    }
    pre{
        background-color: $gray-200;
        padding: 0.3rem;
    }
}