.my-heading{
    font-size: 1.5rem;
    font-weight: 300;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
}

.sub-heading{
    font-size: 1.15rem;
    font-weight: 300;
    border-bottom: 1px solid $primary;
    padding-bottom: 0.3rem;
    margin-top: 1rem;
}