.sidebar-nav{
    .nav-link{
        padding: 1rem 3rem 1rem 1rem;
        border-bottom: 1px solid $gray-200;
        &:hover{
            background-color: $gray-200;
        }
        &.active{
            border-left: 7px solid $gray-500;
            background-color: $gray-100;
        }
    }
}
