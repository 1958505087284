.dropzone{
    padding: 2rem;
    cursor: pointer;
    border: 3px dashed $gray-300;
    color: $text-muted;
    text-align: center;
    &.hover{
        border-color: $gray-700;
        color: $body-color;
    }
}

.pdf-files-container{
    max-height: 600px;
    overflow-y: auto;
}
.pdf-file{
    display: inline-block;
    border: 1px solid $gray-400;
    width: 250px;
    padding: 0.2rem 0.4rem;
    margin: 0 0.6rem 0.4rem 0;
    text-align-last: left;
    .left{
        float: left;
        margin-right: 5px;
    }
    .center{
        float: left;
        width: 165px;
        white-space: nowrap;
        overflow: hidden;
    }
    &.no-trash .center{
        width: 185px;
    }
    .right{
        float: right;
        vertical-align: middle;
        color: $danger;
        padding: 0.8rem 0 0.8rem 0.3rem;
        border-left: 1px solid $gray-200;
        &:hover{
            color: darken($danger, 15%);
            cursor: pointer;
        }
    }
}